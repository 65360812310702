.black {
  height: 70px;
  width: 70px;
  float: right;
}

.white {
  height: 70px;
  width: 70px;
  float: right;
}
